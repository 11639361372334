import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../UI/Modal/Modal';
import logo from '../../../assets/images/vitusLogo.png';
import './PrivateKeyNotFoundView.scss';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/configureStore';

interface IProps {
    show: boolean;
}

const PrivateKeyNotFoundView: React.FC<IProps> = props => {
    const authLoading = useSelector((state: AppState) => state.auth.loading);
    const rsaKeyLoading = useSelector((state: AppState) => state.auth.rsaKeyLoading);
    const isPrivateKeyValid = useSelector((state: AppState) => state.auth.isPrivateKeyValid);
    const dispatch = useDispatch();
    const keyChangeHandler = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        let file = event.dataTransfer
            ? event.dataTransfer.files[0]
            : event.target.files[0];
        // console.log(file);
        if (file) {
            let reader = new FileReader();
            reader.onload = onReaderLoad;
            reader.readAsText(file);
        }
    };

    const onReaderLoad = async (event: any) => {
        // localStorage.setItem('isBackupDone', '1');
        // console.log(event.target.result);
        let privateKey = event.target.result;
        // now we got the uploaded key we need to check if it is valid or not
        dispatch(actions.checkPrivateKeyAndLoadOrders(privateKey, false, false));
    };
    // The function below helps bypass the problem where onChange event doesn't trigger for same file
    const onInputClick = (event: any) => {
        event.target.value = '';
    };

    let keyFileInput = (
        <input
            id="myInput"
            type="file"
            onChange={e => keyChangeHandler(e)}
            onClick={e => onInputClick(e)}
        />
    );

    return (
        <Modal
            show={props.show && !rsaKeyLoading}
            modalClosed={() => console.log('close modal')}
        >
            {authLoading ? (
                'Loading ....'
            ) : (
                <div className="modalContainer">
                    <div className="header">
                        <img src={logo} alt="Logo" />
                        <h4 className="title">
                            Privater Schlüssel ist ungültig
                        </h4>
                    </div>
                    {!isPrivateKeyValid ? (
                        <div className="errorMsg">
                            Privater Schlüssel ist fehlerhaft. Bitte importieren
                            Sie den passenden.
                        </div>
                    ) : null}
                    <p className="description">
                        Ihr Schlüssel stimmt nicht mit dem aktuellen
                        öffentlichen Schlüssel überein. Somit können Sie
                        Nachrichten nicht entschlüsseln.
                    </p>
                    <a
                        href="mailto:support@amamed.de"
                        style={{ textDecoration: 'none' }}
                    >
                        <div className="selectBox">
                            <div className="headline">Support anschreiben</div>
                            <div className="desc">
                                Schreiben Sie eine Email an support@amamed.de
                            </div>
                        </div>
                    </a>
                    <div className="selectBox">
                        <div className="headline">
                            Schlüssel neu importieren
                        </div>
                        <div className="desc">
                            Einen anderen Schüssel importieren
                        </div>
                        {keyFileInput}
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default PrivateKeyNotFoundView;
